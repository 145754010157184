/*-----------------------------------------------------------------------------------

    Template Name: Gothic - Architechture Bootstrap 5 HTML Template
    Version: 1.0

-----------------------------------------------------------------------------------
    
    CSS INDEX
    ===================
	
    01. Template default CSS
    02. Header CSS
    03. Hero CSS
    04. Component CSS
        4.1 Button CSS
        4.2 History CSS
        4.3 Service CSS
        4.4 Funfact Counter CSS
        4.5 Project Tab CSS
        4.6 Client CSS
        4.7 Brand Logo CSS
        4.8 News CSS
        4.9 Team CSS
        4.10 Mobile Menu CSS
        4.11 Breadcrumb CSS
    05. Pages CSS
        5.1 Project Page CSS
        5.2 Blog Page CSS
        5.3 About Page CSS
        5.4 Contact Page CSS
    06. Footer CSS

-----------------------------------------------------------------------------------*/
@import "./bootstrap/functions";
@import "variabls";
@import "custom-variables";
@import "./bootstrap/bootstrap";

@import "common";
@import "header/header";
@import "hero/hero-slider";
@import "component/button";
@import "component/history";
@import "component/service";
@import "component/funfact-counter";
@import "component/project-tab";
@import "component/client";
@import "component/brand-logo";
@import "component/news";
@import "component/team";
@import "component/mobile-menu";
@import "component/breadcrumb";
@import "pages/project";
@import "pages/blog";
@import "pages/about";
@import "pages/contact";
@import "footer/footer";
